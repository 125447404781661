<template>
  <div>
    <p>
      The Creation Lab’s “Start a story” challenge has unleashed a torrent of creative tale telling. LEGO Universe fans have practically buried Professor Brickkeeper under a pile of inspiring stories, and now is the time for that creativity to shine here on the LEGO Universe News Network!
    </p>
    <p>
      Aldric Saucier is helping to develop an original epic story for LEGO Universe. He’s the mastermind behind characters like Numb Chuck, Friendly Felix, and Captain Jackknife, and he’s also a renowned toy inventor. We hope you enjoy Aldric’s comments on a few of your incredible creations!
    </p>
    <!-- pic210C668EED588E7809FE7C7ABB18DA25.jpg -->
    <img src="">
    <p>
      <b><router-link :to="{ name: 'creation', params: {id: '631c3806-d99d-452e-98b2-4b6f1f0b6061', challengeId: '1A59BF96-9CEC-472F-9CE3-77B1558B528B'} }">'Big Foot' Warp Speed Drag Racer</router-link>, by boydkid</b>
    </p>
    <p>
      <i>This is a fun story filled with great ideas and character names! I particularly enjoyed the quotes from the different characters. Unique voices came through in each bit of dialogue. The idea of a drag racer ripping a hole in the fabric of reality, thereby creating a gateway into LEGO Universe is just awesome. That imaginative twist sets up many possibilities by leaving the reader wondering “Where did Mike go?” and “What is this growing portal?” This is an excellent story written by a talented author.</i>
    </p>
    <!-- picB323F7CDA1BE5021FFE2ADEE3A39BD99.jpg -->
    <img src="">
    <p>
      <b><router-link :to="{ name: 'creation', params: {id: '2d5dec21-e3f9-4d5b-af12-9fe3d5884647', challengeId: '1a59bf96-9cec-472f-9ce3-77b1558b528'} }">Hitch-Hiker's Guide to LEGO Universe</router-link>, by mailman632</b>
    </p>
    <p>
      <i>The idea of finding building instructions and the pieces for a portal leading to LEGO Universe is super creative. The end of this tale also has a fun hook: That after finding the portal while riding his faithful bike, Mailman632 accidentally leaves the bike behind when he travels to another galaxy. I imagined that in the next chapter the bike would somehow find its way into LEGO Universe and rescue Mailman632 in the nick of time!</i>
    </p>
    <!-- picD87168D0DC0886B1B7311AAEA7E0DE01.jpg -->
    <img src="">
    <p>
      <b><router-link :to="{ name: 'creation', params: {id: '713feb86-9370-4e24-a9d2-e03b1d3422b0', challengeId: '1a59bf96-9cec-472f-9ce3-77b1558b528b'} }">My entrance to LU</router-link>, by Darkskiez117</b>
    </p>
    <p>
      <i>The idea of a dimension between My LEGO Network and LEGO Universe is very imaginative. When Darkskiez117 finds himself in that strange dimension, his fear and confusion are nicely balanced by the gatekeeper’s quote about LEGO Universe being a “place where the only ruler is imagination and creativity thrives.” The story builds anticipation for what is beyond the door by hinting at the good experiences to come.</i>
    </p>
    <!-- pic17DDBF005E35CDC196AD10BB98459746.jpg -->
    <img src="">
    <p>
      <b><router-link :to="{ name: 'creation', params: {id: '21eae438-edc4-4f25-bb22-5b966430ca3d', challengeId: '1A59BF96-9CEC-472F-9CE3-77B1558B528B'} }">THE STORY</router-link>, by alec2228</b>
    </p>
    <p>
      <i>This story has a lot going for it to make it fun. The main character finds a message in a bottle that he later discovers was written by a pirate. He then boards a ship to an island where a ninja clan opens a treasure chest to another dimension: LEGO Universe! The individual ideas are straight forward, but the details draw the reader in and add a lot of humor to a totally over the top situation. The comic book style format is also a great way to express this short story.</i>
    </p>
    <!-- picA98F0F1596FD4EBF58B8C137A8AECEEA.jpg -->
    <img src="">
    <p>
      <b><router-link :to="{ name: 'creation', params: {id: 'de8122fb-48b2-4b2e-a337-a0adfc63868d', challengeId: '1A59BF96-9CEC-472F-9CE3-77B1558B528B'} }">The Conveyors</router-link>, by Texas520</b>
    </p>
    <p>
      <i>This story leaves a lot of questions unanswered, like “What is the machine? What are the veggie minifigs doing in a storehouse?” Those questions inspire the reader to want to know more. The ideas are also fun and interesting, and major kudos for going the extra mile and animating the story idea. The stop motion technique with LDD is very impressive!</i>
    </p>
    <p>
      Thanks Aldric! And thanks to all you other artists and storytellers who are submitting to the Creation Lab’s “Start a story” challenge! Keep those submissions coming! Fans have already uploaded tons of great tales including some incredible serials, so surf through those creations if you’re seeking inspiration!
    </p>
  </div>
</template>
